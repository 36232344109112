.navbar-expand-lg {
    .navbar-nav .nav-link {
        font-family: "basier_circlemedium", sans-serif;
        padding-left: 24px;
        padding-right: 24px;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 1px;
        color: #2f3233;
        position: relative;
        transition: color 0.3s ease;
    }
    .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
}