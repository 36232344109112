.app-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.app-loader-container svg {
    width: 50px;
    height: 50px;
    animation: scaleGozealZ 1.5s .1s infinite;
}

/* || Scale goZeal Z */
@keyframes scaleGozealZ {
    0% { transform: scaleX(1); }
    50% { transform: scale3d(1.1, 1.1, 1.1); }
    100% { transform: scaleX(1); }
}
