.skeleton-list-view-top-new-btn,
.skeleton-list-view-bottom-new-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.skeleton-list-view-top-new-btn {
    margin-bottom: 1.5rem;
}
.skeleton-list-view-bottom-new-btn {
    margin-top: 1rem;
}

.skeleton-list-view-header,
.skeleton-list-view-row {
    display: flex;
}
.skeleton-list-view-row {
    height: 46px;
}
.skeleton-list-view-columns {
    margin-left: auto;
}
.skeleton-list-view-column {
    margin: 0 15px;
    &:last-child {
        margin-right: 0;
    }
}
.skeleton-box-shadow {
    box-shadow: 0 0 3px rgba(21, 23, 23, 0.2);
    background-color: rgba(255, 255, 255, 1.7);
}
.skeleton-board {
    display: grid;
    grid-template-areas: "a a";
    justify-content: space-between;
    align-items: baseline;
    grid-row-gap: 4px;
    row-gap: 4px;
    column-gap: 8px;
}
