.search-container {
    align-items: center;
    background-color: #fff;
    border: 1.6px solid #dee2e6;
    border-radius: 6px;
    box-shadow: 0 0 3px #15171733;
    display: flex;
    height: 48px;
    margin: 30px 30px 0 30px;
    .clear-btn {
        cursor: pointer;
        visibility: hidden;
        &.visible {
            visibility: visible;
        }
    }

    .search-input {
        width: 100%;
        border: none;
        height: 46px;
        outline: none;
        border-left: 1px solid #dee2e6;
        padding: 0 10px;
    }
    
    .search-box {
        margin: 0px 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: inherit;
    }
}