.content-layout {
    height: 100vh;
    width: 100vw;
    display: flex;
    min-height: 500px;
    transition: all 0.3s ease;
}

#navigation-panel {
    max-width: 100px;
    width: 100%;
    transition: all 0.3s ease;
    max-height: 100vh;
    position: relative;
    flex-shrink: 0;
    &.expanded {
        max-width: 260px;
        width: 100%;
        transition: all 0.3s ease;
        .navigation-panel-container {
            max-width: 260px;
            width: 100%;
            transition: all 0.3s ease;        
        }
        .navigation-item-container {
            width: 100%;
            transition: all 0.3s ease;
        }
        .gz-nav-btn {
            padding: 16px 19px;
            justify-content: normal;

            span {
                white-space: nowrap;
                visibility: visible;
                opacity: 1;
                font-size: 16px;
                transition: opacity 0.3s ease, visibility 0.2s linear;
            }

            svg {
                margin-right: 12px;
            }
        }
    }
    .navigation-panel-container {
        max-width: 100px;
        width: 100%;
        transition: all 0.3s ease;
        height: 100%;
        position: fixed;
        // z-index: 102;
        top: auto;
        left: auto;
        bottom: auto;
        border-right: 1px solid #dee2e6;
        background: #fff;
    }
    .navigation-item-container {
        width: 100%;
        transition: all 0.3s ease;
        flex-wrap: nowrap;
        height: 100%;
    }
    .gz-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        padding-top: 26px;
    }
    .navigation-item-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
    .navigation-collapse-icon {
        position: absolute;
        z-index: 1;
        right: -9px;
        top: 47px;
        border-radius: 50%;
        border: 2px solid #fff;
        background: #fff;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 2px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .25);

        svg {
            width: 100%;
            height: 100%;
        }
    }
    .gz-nav-btn {
        font-family: 'basier_circleregular';
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        color: #63676F;
        padding: 16px 12px;
        max-width: 212px;
        width: 100%;
        margin: auto auto 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:hover, &.active {
            background: #eaeff3c7;
        }

        svg {
            width: 28px;
            height: 28px;
        }

        // span {
        //     visibility: hidden;
        //     opacity: 0;
        //     font-size: 0;
        //     line-height: normal;
        // }
    }

    .gz-nav-collapse-icon {
        position: absolute;
        z-index: 1;
        right: -38px;
        top: 47px;
        border-radius: 50%;
        border: 2px solid #fff;
        background: #fff;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 2px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .25);
        display: none;

        &:hover { border: 2px solid #7F7FF0; }

        svg {
            width: 100%;
            height: 100%;
            &.rotate { transform: rotate(179deg); }
        }
    }
}

#navigation-panel + .panel-content {
    width: 100%;
}