.pagination-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0 10px;
    font-size: 14px;
    margin-bottom: 0;

    .pagination-item {
        list-style-type: none;
        padding: 6.5px 12.5px;
        border-radius: 4px;
        cursor: pointer;

        &.selected {
            background: #7F7FF0;
            color: #fff;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.7;
            cursor: not-allowed;
        }

        &.dots {
            cursor: default;
        }

        &.leftarrow svg {
            transform: rotate(180deg);
        }

        &.leftarrow, &.rightarrow {
            padding: 5px 7px;
        }
    }
}

.project-page-number, .template-row {
    color: #7F7FF0;
    &.selected {
        background: #7F7FF0!important;
        color: #fff;
    }
    &:not(.selected):not(.dots):hover {
      color: #7F7FF0;
      background: #e8eaed;
    }
}

.task-page-number {
    color: #4ECD62;
    &.selected {
        background: #4ECD62 !important;
        color: #fff;
    }
    &:not(.selected):not(.dots):hover {
      color: #4ECD62;
      background: #e8eaed;
    }
}

.milestone-page-number {
    color: #E2798F;
    &.selected {
        background: #E2798F !important;
        color: #fff;
    }
    &:not(.selected):not(.dots):hover {
      color: #E2798F;
      background: #e8eaed;
    }
}
