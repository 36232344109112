.zd-navigation-tab {
    padding: 9px 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .zd-navigation-items {
        display: flex;
        align-items: center;
    }

    .nav-link-order-1,
    .nav-link-order-2,
    .nav-link-order-3,
    .nav-link-order-4 {
        font-size: 16px;
        font-family: 'basier_circleregular', sans-serif;
        text-decoration: none;
        color: #63676F;
        line-height: 21px;
        padding: 12px 15.75px;
        min-width: 130px;
        border-bottom: 3px solid;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-color: transparent;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        background: #fff;
        text-align: center;
        cursor: pointer;

        &:not(.active):hover {
            background: #F8F9FB;
            border-bottom: 3px solid;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-color: #dee2e6;
            color: #63676F;
        }
    }
    .nav-link-order-1.active {
        color: #7F7FF0;
        border-bottom: 3px solid #7F7FF0;
        background: rgba(127, 127, 240, 0.05);
    }

    .nav-link-order-2.active {
        color: #4ECD62;
        border-bottom: 3px solid #4ECD62;
        background: rgba(78, 205, 98, 0.05);
    }

    .nav-link-order-3.active {
        color: #E2798F;
        border-bottom: 3px solid #E2798F;
        background: rgba(226, 121, 143, 0.05);
    }

    .nav-link-order-4.active {
        color: rgb(240, 135, 115);
        border-bottom: 3px solid rgb(240, 135, 115);
        background: rgba(240, 135, 115, 0.05);
    }

    &.secondary {
        padding: 11px 30px;
        .nav-link-order-1,
        .nav-link-order-2,
        .nav-link-order-3,
        .nav-link-order-4 {
            border-radius: 4px;
            color: #63676f;
            cursor: pointer;
            padding: 2px 8px;
            border: 0;
            font-size: 14px;
            &:not(.active):hover {
                background: #eaeff3c7;
                border: 0;
            }
        }
    }
}

.zd-navigation-content {
    // overflow-y: scroll;
    height: calc(100vh - 58px);

    .scrollable-container {
        height: calc(100vh - 200px);
        overflow-y: scroll;
        padding: 0 30px 30px;
        overflow-x: scroll;

        &.logs-scrollable-container {
            height: calc(100vh - 248px);
            max-width: calc(100vw - 100px);
        }
    }

    .logs-search {
        margin-bottom: 30px;
    }
    .pdf-transform-container, .insured-container, .account-container, .line-of-business-container, .carrier-container, .preprocessing-container, .logs-container {
        .scrollable-container{
            height: calc(100vh - 284px);
        }
    }
}