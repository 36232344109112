.user-avatar {
    height: 32px;
    width: 32px;
    border-radius: 100% !important;
    background: rgba(150, 70, 177, 1);
    font-size: 11px;
    font-weight: 400;
    border: 1px solid rgba(0, 0, 0, 0.16);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.user-avatar-detail-container {
    display: flex;
    flex-direction: column;
    margin-left: 9px;
}

.user-avatar-container {
    display: flex;
    align-items: center;
    cursor: default;
}

.user-avatar-name {
    font-size: 14px;
    font-weight: 600;
    color: #263238;
}

.user-avatar-email {
    font-size: 12px;
    font-weight: 400;
    color: #8993AE;
}

.topbar-profile-menu {
    width: max-content;
    height: auto;
    display: flex;
    flex-direction: column;
    border: 0.1px solid rgba(150, 70, 177, 1);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    background-color: #fff;

    .user-avatar-container {
        padding: 10px;
    }

    .user-avatar-email {
        color: #8993AE;
    }
}

.topbar-menu-item {
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 400;
    text-align: end;
    background-color: #efefef;
    cursor: pointer;

    &:hover {
        background-color: #dfdfdf;
    }
}

.topbar-arrow {
    border: solid #afb1b7;
    border-width: 0px 2px 2px 0px;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(45deg);
}

.topbar-profile-container {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.manage-user-menu-trigger {
    display: flex;
    align-items: center;
}

.topbar-profile-toggle {
    cursor: pointer;
}