body {
    font-family: 'basier_circleregular', sans-serif;
    font-size: 14px;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    min-height: calc(100vh);
    max-height: calc(100vh);
}
.text-ellipsis-w40 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 40%;
    height: auto;
}
.file-item {
    box-shadow: 0 0 10px #eaeff3cc;
    padding: 8px;
    background: #FFF;
    border: 1px solid transparent;
}
.file-line-item-container {
    width: 100%;
}
.file-header {
    background-color: rgba(240, 240, 254, 1);
    padding: 10px;

    .file-header-item {
        font-size: 11px;
        text-transform: uppercase;
    }

    &.sticky {
        position: sticky;
        top: 0;
        z-index: 1;
    }
}
.file-body {
    box-shadow: 0 0 10px #eaeff3cc;
    max-height: 42px;
    background: #FFF;
    justify-content: normal;
    transition: all 00.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-duration: 0.5s;
    animation-name: fadein;
    border: 1px solid transparent;

    .file-body-item {
        font-size: 13.5px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        &.no-wrap {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .file-body-item-clickable {
        cursor: pointer;
        text-decoration: underline;
    }
}

.file-header-item, .file-body-item {
    padding: 0 10px;
    min-width: 120px;
    height: 42px;

    &:first-child {
        width: 120px;
        min-width: 120px;
        max-width: 120px;
        padding-right: 0;
    }
}
.w-60 {
    width: 60%;
}
.w-40 {
    width: 40%;
}
.w-90 {
    width: 90%;
}
.w-30 {
    width: 30%;
}
.w-70 {
    width: 70%;
}
.w-75 {
    width: 75%;
}
.w-20 {
    width: 20%;
}
.w-15 {
    width: 15%;
}
.w-10 {
    width: 10%;
}
.mnw-15 {
    min-width: 15%;
}
.cursor-pointer {
    cursor: pointer;
}
.file-content-container {
    padding-bottom: 50px;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    min-height: calc(100vh - 19rem);
    max-height: calc(100vh - 19rem);
}
.gz-scroll::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #F8F9FB;
}

.gz-scroll::-webkit-scrollbar {
	width: 8px;
    height: 8px;
	background-color: #F8F9FB;
}

.gz-scroll::-webkit-scrollbar-thumb {
	background-color: #7F7FF0;
	border: 0;
    border-radius: 10px;
}
.calendar {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    min-height: 38px;
    padding: 2px 8px;
}
.gz-btn-primary-violet {
    font-family: "basier_circlemedium", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #ffffff;
    background: #7f7ff0;
    box-shadow: 5px 5px 0px rgba(127, 127, 240, 0.25);
    border: 1px solid #151717;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 24px;
    letter-spacing: 2px;
    transition: background 0.3s ease;
    max-width: 226px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    &.loading {
        position: relative;
        color: transparent;
    }
    &.disabled{
        margin-top: 10px;
        background: #d3d3d3;
        cursor: not-allowed
    }
}
.error-text {
    color: #d13212;
}
.error-border {
    border: 1px solid #d13212 !important;
    border-radius: 4px;
}
.text-gz-grey {
    color: #63676F;
}
.gz-btn-secondary {
    font-family: 'basier_circlemedium', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #151717;
    background: #fff;
    box-shadow: none;
    border: 1px solid #151717;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 36px;
    letter-spacing: 2px;
    transition: background 0.3s ease;
}
.small-text {
    font-size: 11px;
}
.form-check-input {
    border: 1px solid #7F7FF0;
    &:checked {
        background-color: #7F7FF0;
        border-color: #7F7FF0;
    }
}

.inline-spinner {
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: spinner 1s linear infinite;

    width: 24px;
    height: 24px;
    border: 2px solid;
    border-color: #fff #fff #63676f;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: block;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-container {
    width: 100%;
}

.spinner {
    width: 22px;
    height: 22px;
    border: 2px solid #373535;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: 50%;
    box-sizing: border-box;
    animation: rotation 0.7s linear infinite;
}

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }





.data-ingestion-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust space between items */
    .select-container {
        margin: 30px 30px 0 30px;
    }
}

.select-container {
    display: flex;
    gap: 20px; /* Space between the dropdowns */
}

.form-select {
    width: 200px; /* Width of the select dropdown */
    padding: 8px;
    font-size: 14px;
}
.selected-files-header {
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 12px;  /* Padding around the text */
    border-radius: 5px;  /* Rounded corners */
    margin-bottom: 10px;
}
.refresh-icon-img {
    margin-left: 10px;
    padding: 1px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    }
.close-icon-img {
        margin-left: 10px;
        padding: 1px;
        width: 15px;
        height: 15px;
        cursor: pointer;
        }    
.gz-btn-transparent
{
    background: transparent;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 2px 2px 0px rgba(127, 127, 240, 0.25);
}
.list-alignment
{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.mrgn-lft-8
{
    margin-left: 8px;
}
.mrgn-rt-8
{
    margin-right: 8px;   
}
.invisible-component
{
    display:none;
}
.transform-entry-new-btn {
    color: #7F7FF0;
    font-family: 'basier_circlemedium', sans-serif;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    width: auto;
    padding-left: 34px;
    margin: 20px 20px 0 0;
    max-width: fit-content;
    padding-bottom: 30px;
}
.gz-btn-new-entry {
    font-family: "basier_circlemedium", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 8px;
    text-transform: uppercase;
    color: #ffffff;
    background: #7f7ff0;
    border-radius: 5px;
    padding: 10px 14px;
    transition: background 0.3s ease;
    max-width: 186px;
    text-align: justify;
    cursor: pointer;
    &.loading {
        position: relative;
        color: transparent;
    }
    &.disabled{
        margin-top: 10px;
        background: #d3d3d3;
        cursor: not-allowed;
    }
}

.data-ingestion-selection-container {
    display: flex;
    gap: 12px;
    font-size: 14px;
    font-weight: 800;
    margin-top: 10px;
}

.data-ingestion-selection-item {
    padding: 4px 10px;
    cursor: pointer;
    &:hover, &.selected {
        background-color: #7F7FF0;
        color: #FFF;
    }
}

.data-ingestion-action-container {
    color: #6b6b6f;
    font-weight: 800;
    display: flex;
    justify-content: end;
    gap: 12px;
    padding-left: 10px;
    font-size: 13px;
}

.data-ingestion-action {
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 3px;
    &:hover {
        color:  #444546;
    }
    cursor: pointer;
    &.disabled {
        color: #d3d3d3;
        cursor: not-allowed;
    }
}

.data-ingestion-client-separator {
    margin: 2px 0;
    margin-top: 3px;
    margin-bottom: -10px;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .animate-icon {
    animation: rotate 2s linear infinite;
  }

  @keyframes moveArrow {
    0% {
      transform: translateY(-16px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  .animate-arrow .arrow {
    animation: moveArrow 1s ease-in-out;
  }


.checkbox-cell-disabled {
    cursor: not-allowed;
}

.data-ingestion-selection-action-container {
    display: flex;
    align-items: last baseline;
    border-bottom: 1px solid #dee2e6;
}

.select-data-ingestion-client {
    cursor: not-allowed;
}

.data-ingestion-client-link {
    text-decoration: underline;
    color: #7F7FF0;
    cursor: pointer;
    padding-left: 10px;
}

.transform-view-edit {
    display: flex;
    margin-left: 16px;
    gap: 10px;
}

.transform-table-data-header {
    font-family: "basier_circlemedium";
}

.transform-modal-header {
    display: flex;
    align-items: baseline;
}

.modal-heading {
    font-size: 16px;
    font-weight: 600;
    font-family: "basier_circlemedium";
}

.required-field {
    color: #d13212;
    margin-left: 4px;
}

.text-input-container {
    position: relative;
    display: flex;
    align-items: center;
    .max-no-character-indicator {
        position: absolute;
        right: 0;
        padding-right: 10px;
        opacity: 0.5;
    }
}
